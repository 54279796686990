import { useI18n } from "#i18n";
import { ref } from "vue";
import { iocContainer } from "~/inversify.config";
import type { IUser, UsersGatewayInterface } from "~/entities/user";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { toaster } from "~/service/toaster";

export const useProfileInfo = () => {
  const isUserLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const { t } = useI18n();
  const api = iocContainer.get<UsersGatewayInterface>(
    INJECT_SYMBOLS.UsersGatewayInterface,
  );

  const getProfileInfo = async (id: string): Promise<IUser | undefined> => {
    try {
      isUserLoading.value = true;
      isError.value = false;
      const apiResponse = await api.getOneUserById(id as string);
      return apiResponse;
    } catch (err) {
      toaster().error(t("error_fetch_user_unknown_id"));
      isError.value = true;
      return undefined;
    } finally {
      isUserLoading.value = false;
    }
  };

  return {
    isUserLoading,
    isError,
    getProfileInfo,
  };
};
